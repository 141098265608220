/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/layout/Layout'
import Section, { SectionScroller } from '../components/layout/Section'

import heroVideo from '../assets/media/herovideo.mp4'

const ThankYouPage = ({data}) => {
    const logo = <div className="logoContainer">
        <Img
            fixed={data.logo_light.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            height={59}
            alt="BCM Solutions"
            className="logoDark"
        />
        <Img
            fixed={data.logo_dark.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            height={59}
            alt="BCM Solutions"
            className="logoLight"
            aria-hidden="true"
        />
        </div>

    const isDesktop = useMediaQuery({query: '(min-width: 1024px)'})
    const isTablet = useMediaQuery({query: '(min-width: 767px) and (max-width: 1024px)'})
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const [deviceState, setDeviceState ] = useState('mobile')

    let device = deviceState
    switch(true){
        case isDesktop: 
            device = 'desktop'
        break
        case isTablet:
            device = 'tablet'
        break
        case isMobile: default:
            device = 'mobile'
        break
    }

    if (typeof window === 'undefined') {
        device = 'mobile'
    }

    useEffect(() => {
        setDeviceState(device)
    },[deviceState, device]);

    return (
        <Layout title={'Websites | Contemporary Web Design | BCM Solutions'} descr={'Ultra fast, custom website solutions and digital marketing consulting. Beautiful websites tailor made to your needs. Integrate with existing content management systems or use your own!'} logo={logo} device={deviceState} noindex={true}>
            <SectionScroller device={device} headerScrollStart={550}>
                <Section 
                    forceVisible={true} 
                    name={false} 
                    classes="isFH isContact isTy" 
                    theme="dark" 
                    video={{src: heroVideo, poster: data.videoImg.childImageSharp}} 
                    gradient={{from: '#325d7f', to: '#f2727f'}} 
                    device={deviceState}
                    content={{title: `Thank you!`, subtitle: `Your submission was successful`, body: `Thanks so much for reaching out to us. We're really excited to see what you have to say. We'll get back to you as quickly as we can. (Usually within 24 hours)`}}
                    layout="OneUp"
                />
                <Section 
                    classes="isFooter" 
                    theme="light" 
                    name={false}
                    classTheme="light" 
                    device={deviceState}
                    layout="Footer"
                />
            </SectionScroller>
        </Layout>
    )
}

export default ThankYouPage

export const ThankYouQuery = graphql`
query ThankYouQuery {
    logo_light: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
            fixed(width: 189) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
   }
   logo_dark: file(relativePath: {eq: "logoDark.png"}) {
        childImageSharp {
            fixed(width: 189) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    videoImg: file(relativePath: {eq: "videoBackground.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(width: 700) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
}
`